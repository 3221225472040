import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navbar, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import 'firebase/auth';
import firebase from 'firebase/app';
import logo from 'images/logo_reg.png';

import * as routes from 'constants/routes';
import Home from 'routes/Home';
import Tour from 'routes/tour';
import Tours from 'routes/Tours';
import Login from 'routes/Login';
import Signup from 'routes/Signup';
import ForgotPassword from 'routes/ForgotPassword';
import ResetPassword from 'routes/ResetPassword';
import Order from 'routes/Order';
import Profile from 'routes/Profile';
import VerifyEmail from 'routes/VerifyEmail';
import Message from 'routes/Message';
import PrintOrder from 'routes/PrintOrder';
import { OrderSummary } from '../routes/OrderSummary';

import { getCurrentUser, getUsers } from '../services/users/actions';
import { logout } from '../services/auth/actions';
import Footer from '../components/layout/Footer';
import {checkIsLoggedIn} from '../helpers/authentication';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
// Load the main scss
import 'styles/index.scss';
// Import the Prime Facess
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// Import bootstrap
import 'styles/bootstrap/bootstrap.css';
import 'react-input-range/lib/css/index.css';

import { getTours } from '../services/tours/actions';
import { getImages } from '../services/gallery/actions';
import { getComments } from '../services/comments/actions';
import { getPickups } from '../services/pickups/actions';
import { getEntrances } from '../services/entrances/actions';
import { getRestaurants } from '../services/restaurant/actions';
import { getUserOrders } from '../services/orders/actions';
import config from '../constants/payments';

class App extends Component {

  state={
    atBottom: false
  }

  logoutUser() {
    const {logout} = this.props;
    logout();
  }

  componentDidMount() {
    const { getCurrentUser } = this.props;
    getCurrentUser();
    document.addEventListener('scroll', this.trackScrolling);
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // this.logoutUser();
      }
    });
    this.fetchData();
  }

  fetchData = async () => {
    const {
      getTours,
      getImages,
      getComments,
      getPickups,
      getEntrances,
      getRestaurants,
      getUserOrders,
      getUsers
    } = this.props;
    try {
      await Promise.all([
        getTours(),

        getImages(),
        getComments(),
        getPickups(),
        getEntrances(),
        getRestaurants(),

        getUserOrders(),
        getUsers(),
      ]);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getUserinfo = () => {
    const { user } = this.props;
    return user && user.agent;
  }

  isBottom = el => el.getBoundingClientRect().bottom - 0.40625 <= window.innerHeight;

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('main-container');
    if (this.isBottom(wrappedElement)) {
      this.setState({atBottom: true});
    } else {
      this.setState({atBottom: false});
    }
  };

  render() {
    const { atBottom, user } = this.state;
    if (user && user.deactivated) {
      this.logoutUser();
    }
    return (
      <div id="main-container" className={`app-container ${(atBottom && 'bottom-reached') || ''}`}>
        <Helmet>
          <script defer src={config.script} />
        </Helmet>
        <div className="container">
          <Navbar expand="lg">
            <Link to={routes.HOME} className="navbar-brand"><img src={logo} alt="logo" style={{height: '70px'}} /></Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="row d-flex justify-content-end" style={{width: '100%'}}>
                {
                  checkIsLoggedIn() ? (
                    <Nav>
                      {
                        this.getUserinfo() ? (
                          <Link to={routes.DASHBOARD} className="nav-link"><h4>Dashboard</h4></Link>
                        )
                          : (
                            <Link to={routes.ORDER} className="nav-link"><h4>Orders</h4></Link>
                          )
                      }
                      <Link to={routes.UPDATE_PROFILE} className="nav-link"><h4>My Profile</h4></Link>
                      <Link to={routes.HOME} onClick={() => this.logoutUser()} className="nav-link"><h4>Logout</h4></Link>
                    </Nav>
                  ) : (
                    <Nav>
                      <Link to={routes.LOGIN} className="nav-link">Login</Link>
                      <Link to={routes.SIGNUP} className="nav-link">Sign Up</Link>
                    </Nav>
                  )
                    }
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <Route exact path={routes.HOME} component={Home} />
        <Route path={routes.TOUR} component={Tour} />
        <Route path={routes.LOGIN} component={Login} />
        <Route path={routes.SIGNUP} component={Signup} />
        <Route path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={routes.AUTH} component={ResetPassword} />
        <Route exact path={routes.ORDER} component={Order} />
        <Route exact path={routes.ORDERSUMMARY} component={OrderSummary} />
        <Route path={routes.DASHBOARD} component={Order} />
        <Route path={routes.UPDATE_PROFILE} component={Profile} />
        <Route exact path={routes.TOURS} component={Tours} />
        <Route exact path={routes.TOURS_FEATURED} render={() => <Tours featured />} />
        <Route exact path={routes.VERIFY_EMAIL} component={VerifyEmail} />
        <Route exact path={routes.MESSAGE} component={Message} />
        <Route exact path={routes.PRINT_ORDER} component={PrintOrder} />
        <Footer />

      </div>
    );
  }
}

export default withRouter(connect(({users}) => ({
  user: users.user
}),
{
  getUsers,
  getCurrentUser,
  getTours,
  getImages,
  getComments,
  getPickups,
  getEntrances,
  getRestaurants,
  getUserOrders,
  logout
})(App));
