import { toastr } from 'react-redux-toastr';

const defaultCancel = () => { console.log('Cancel clicked'); };

export const confirm = (message, onAccept, onCancel = defaultCancel) => {
  const options = {
    onOk: onAccept,
    onCancel
  };

  toastr.confirm(message, options);
};

export const success = (message = '', title = 'Success') => {
  toastr.success(title, message);
};

export const error = (message = '', title = 'Error') => {
  toastr.error(title, message);
};
