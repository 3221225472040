import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import authentication from './auth/reducer';
import users from './users/reducer';

import tours from './tours/reducer';
import gallery from './gallery/reducer';
import guides from './guides/reducer';
import comments from './comments/reducer';
import pickups from './pickups/reducer';
import entrances from './entrances/reducer';
import transports from './transport/reducer';
import restaurants from './restaurant/reducer';

import orders from './orders/reducer';
import payments from './payment/reducer';

const reducers = {
  routing: routerReducer,
  form: formReducer,
  toastr: toastrReducer,
  tours,
  gallery,
  guides,
  users,
  authentication,
  orders,
  comments,
  pickups,
  entrances,
  transports,
  restaurants,
  payments,
};

export default combineReducers(reducers);
