import { all, takeLatest, call, put } from 'redux-saga/effects';
import moment from 'moment';
import Cookies from 'js-cookie';
import firebase from 'firebase/app';
import Firestore from 'firebase-service/Firestore';
import 'firebase/firestore';

import { success, error } from 'helpers/reactToaster';
import { logoutUser } from 'helpers/authentication';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import * as actions from './actions';
import { setCheckoutOrder } from '../payment/actions';

const firestore = new Firestore();


function* getOrders() {
  const result = yield call(firestore.getRSF().firestore.getCollection, firestore.getCollection('order'));
  const orders = firestore.sanitizeSnapshot(result, fields.ORDER);
  yield put(actions.setOrders(orders));
}

function* getUserOrders() {
  const userID = Cookies.get('user');
  if (!userID) return;
  const orderCollectionQuery = firestore.getCollection('order').where('user', '==', userID);
  const result = yield call(firestore.getRSF().firestore.getCollection, orderCollectionQuery);
  const orders = firestore.sanitizeSnapshot(result, fields.ORDER);
  yield put(actions.setUserOrders(orders));
}

function* addOrder({order}) {
  try {
    const result = yield call(firestore.getRSF().firestore.getCollection, firestore.getCollection('order'));
    const orders = firestore.sanitizeSnapshot(result, fields.ORDER);
    order.idnumber = orders.length + 1
    order.ordered = firebase.firestore.Timestamp.fromDate(new Date());
    const orderdate = moment(order.date).toDate();
    order.orderbookeddate = moment(order.date).format('YYYY-MM-DD');
    delete order.date;
    order.date = orderdate;
    yield call(firestore.getRSF().firestore.addDocument, 'order', order);
    // eslint-disable-next-line no-unused-expressions
    order.isprivate && success('Tour booked sucessfully');
  } catch (e) {
    if (e.message === 'Missing or insufficient permissions.') {
      yield call(firestore.getRSF().auth.signOut);
      logoutUser();
      error('Authentication has been refreshed. Please login again.');
    } else {
      error(e.message);
    }
    console.log(e);
  } finally {
    yield put(setCheckoutOrder(null));
  }

}

function* deleteOrder(value) {
  try {
    yield call(firestore.getRSF().firestore.deleteDocument, `order/${value.id}`);
    yield put(actions.getOrders());
    success('Tour removed from cart');
  } catch (e) {
    console.log(e);
    error(e.message);
  }

}

function* updateOrder(value) {
  try {
    yield call(firestore.getRSF().firestore.updateDocument, `order/${value.id}`, value.order);
    yield put(actions.getOrders());
    success('Order has been booked successfully');
  } catch (e) {
    console.log(e);
    error(e.message);
  }

}

function* purchaseOrder(value) {
  try {
    console.log(value.order);
    yield call(firestore.getRSF().firestore.setDocument, `order/${value.id}`, value.order, {merge: true});
    yield put(actions.getOrders());
    success('Order has been purchased');
  } catch (e) {
    console.log(e);
    error(e.message);
  }

}

function* ordersSaga() {
  yield all([
    takeLatest(types.GET_ORDERS, getOrders),
    takeLatest(types.GET_USER_ORDERS, getUserOrders),
    takeLatest(types.ADD_ORDER, addOrder),
    takeLatest(types.DELETE_ORDER, deleteOrder),
    takeLatest(types.UPDATE_ORDER, updateOrder),
    takeLatest(types.PURCHASE_ORDER, purchaseOrder)
  ]);
}

export default ordersSaga;
