import * as types from 'constants/actions';

export const getPickups = () => ({
  type: types.GET_PICKUPS
});

export const setPickups = pickups => ({
  type: types.SET_PICKUPS,
  pickups
});

export const addPickup = pickup => ({
  type: types.ADD_PICKUP,
  pickup
});

export const updatePickup = (id, pickup) => ({
  type: types.UPDATE_PICKUP,
  id,
  pickup
});

export const deletePickup = id => ({
  type: types.DELETE_PICKUP,
  id
});
