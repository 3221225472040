import React, { Component } from 'react';
import ForgotPasswordForm from 'components/forms/ForgotPasswordForm';
import { connect } from 'react-redux';
import { sendResetPasswordEmail } from '../services/auth/actions';
import {checkIsLoggedIn} from '../helpers/authentication';

export class ForgotPassword extends Component {
  componentDidMount() {
    const {history} = this.props;

    if (checkIsLoggedIn()) {
      history.push('/');
    }
  }

  sendRequestEmail = (values) => {
    const {sendResetPasswordEmail, history} = this.props;
    sendResetPasswordEmail(values.email);
    history.push('/message/resetPassword');
  }

  render() {
    return (
      <ForgotPasswordForm onSubmit={this.sendRequestEmail} />
    );
  }
}

export default connect(() => ({
}),
{
  sendResetPasswordEmail
})(ForgotPassword);
