import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import {Button} from 'primereact/button';
import config from '../../../constants/payments';
import { addOrder } from '../../../services/orders/actions';
import { Spinner } from '../../../routes/OrderSummary';

export const CardPayment = ({ formValues, tour }) => {
  const { checkoutOrder } = useSelector(state => state.payments);
  const [transactionCompleted, setTransactionCompleted] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const dispatch = useDispatch();
  const date = moment().format();
  const history = useHistory();

  const paymentConfiguratiion = {
    transactionType: 'sale',
    widgetConfiguration: {
      language: 'EN',
      showTruevo: true,
      iframeStyles: {
        theme: 'plain',
        style: 'body {background:none}'
      }
    },
    transaction: {
      paymentReference: `${tour}-${date}`,
      merchantReference: uuidv4(),
      amount: {
        totalAmount: formValues.amount,
        currencyAlphaCode: 'EUR',
      },
    },
    merchant: config.merchant,
    additionalData: []
  };

  // create order
  const createOrder = (data, actions) => actions.order
    .create({
      purchase_units: [
        {
          description: checkoutOrder?.tourName,
          amount: {
            currency_code: 'EUR',
            value: formValues.amount,
          },
        },
      ],
      // not needed if a shipping address is actually needed
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      },
    })
    .then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });

  // check Approval
  const onApprove = (data, actions) => actions.order.capture().then((details) => {
    // eslint-disable-next-line camelcase
    const { payer, status, purchase_units } = details;
    const body = {
      ...checkoutOrder,
      payment: {
        paypal: true,
        ...details
      }
    };
    setTransactionCompleted(true);
    dispatch(addOrder(body));
  });

  // capture likely error
  const onError = (data, actions) => {
    console.log('An Error occured with your payment');
    // eslint-disable-next-line no-alert
    window.alert("'An Error occured with your payment");
  };

  const handleSuccessfulTransaction = (transaction) => {
    const body = {
      ...checkoutOrder,
      payment: transaction,
    };
    dispatch(addOrder(body));
    setTransactionCompleted(true);
  };

  const openCheckout = () => {
    if (checkoutOrder) {
      window.TruevoCheckout.initialize(paymentConfiguratiion);
      const truevoCheckout = window.TruevoCheckout.mount('#truevo_form_container');

      truevoCheckout.addEventListener('loadCompleted', (res) => {
        console.log(res.detail);
      });

      truevoCheckout.addEventListener('loadFailed', (res) => {
        console.log(res.detail.error);
      });

      truevoCheckout.addEventListener('transactionCompleted', (res) => {
      // handle a successful transaction
        handleSuccessfulTransaction(res.detail);
      });

      truevoCheckout.addEventListener('transactionDeclined', (res) => {
        console.log(res.detail, checkoutOrder);
      // handle a transaction that was received but declined in processing
      });

      truevoCheckout.addEventListener('transactionFailed', (res) => {
        console.log(res.detail);
      // handle a transaction that failed to process due to a server or communication error
      });
    }
  };

  useEffect(openCheckout, [checkoutOrder]);
  useEffect(() => {
    if (transactionCompleted) {
      setTimeout(() => {
        history.push('/order');
      }, 3000);
    }
  }, [transactionCompleted]);

  // Af85j_n1Sdn9Pv77gCdXeGhKPqCoYZbjaGHU6cwzurLXvW_MVajFD3oOpNthgWB8xCKLBg6GNlg3xJdh
  // AYBuAkj5ByUYlbfiFQgkkHocjr1-dC0KKMbkpI0YX0Lpg5ZmlBiMa8e25RfTIFggN9JDgsWbGYxW_mUV
  // ATmYsbK-WbZCOtkmtJD-BlNULaJSX0ekUkzRgh2bCcv-p-a9ltblj0Z0jFDhsSG3Ym0xX37F_k2Ojt3E
  const ENV = process.env.NODE_ENV === 'production'
    ? 'sandbox'
    : 'sandbox';
  const CLIENT = {
    sandbox: 'ATmYsbK-WbZCOtkmtJD-BlNULaJSX0ekUkzRgh2bCcv-p-a9ltblj0Z0jFDhsSG3Ym0xX37F_k2Ojt3E',
    production: ENV,
  };
  return (
    <div className="application" style={{ textAlign: 'center' }}>
      <div className="total-container">
        <label className="total">Total payment to be deducted: </label>
        <span className="theme-red"> € {checkoutOrder?.amount}</span>
      </div>
      <div>
        {transactionCompleted && (
          <div>
            <Spinner />
            <div className="order-redirect">
              Please wait... Redirecting to Orders page
            </div>
          </div>
        )}
      </div>
      {/* <div className="payment-tab">
        <Button className="paycard-tab" label="Pay By Card" />
        <PaypalButton
          client={CLIENT}
          env={ENV}
          commit
          currency="EUR"
          total={checkoutOrder?.amount}
        />
      </div> */}
      <div className="payment-tab">
        <Button className="paycard-tab" label="Pay By Card" />
        <PayPalScriptProvider options={{ 'client-id': CLIENT.sandbox, currency: 'EUR' }}>
          <PayPalButtons style={{ layout: 'horizontal' }} createOrder={createOrder} onApprove={onApprove} onError={onError} />
        </PayPalScriptProvider>
      </div>
      <div id="truevo_form_container" />
    </div>
  );
};
