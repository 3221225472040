import React from 'react';
import { Link } from 'react-router-dom';
import { Rating } from 'primereact/rating';
import { ImageComponent } from '../utils/ImageComponent';
import { IMAGE_DISPLAY } from '../../helpers/image';

export default function General(props) {
  const {
    limit,
    featured,
    latestTours,
    filteredLatestTours,
    getImageUrl,
    getRating,
    getCommentsLength,
    checkEmptyFilters,
    getMillis,
    renderSchedSwitch,
  } = props;

  let tours = (checkEmptyFilters() ? latestTours : filteredLatestTours).sort(
    (a, b) => getMillis(b.timeadded) - getMillis(a.timeadded),
  );

  if (limit) {
    tours = tours.slice(0, limit);
  }

  return (
    <div className="row">
      {tours.map((tour, i) => (
        <div key={i} className="col-lg-3 p-3">
          <Link to={`/tour/${encodeURIComponent(tour.name)}-${tour.uuid}`}>
            <div
              className="tour-card w-100 animated fadeIn"
              style={{
                height: '200px',
              }}>
              <ImageComponent
                alt=""
                height={200}
                width="100%"
                type={IMAGE_DISPLAY}
                image={getImageUrl(tour.displayimage || tour.photos[0])}
              />
              <div className="tour-desc">
                <div className="tour-desc-background" />
                <div className="row">
                  <div className="col">
                    <p className="tour-name">{tour.name}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <span style={{ fontSize: '12px' }} className="mt-3 mb-3">
                      {tour.pricing.tourtype === 'private'
                        ? 'Private'
                        : `from € ${Number(tour.pricing.price.adult).toFixed(
                          2,
                        )}`}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col" style={{ fontSize: '12px' }}>
                    {renderSchedSwitch(tour)}
                  </div>
                </div>
                {/* {featured && (
                  <div className="row">
                    <div className="col d-flex justify-content-between">
                      <Rating
                        value={getRating(
                          tour.rate
                            ? tour.rate.filter(rate => rate.confirmed)
                            : false,
                        )}
                        stars={5}
                        readonly
                        cancel={false}
                      />
                      <span style={{ fontSize: '12px' }}>{`${getCommentsLength(
                        tour.uuid,
                      )} Reviews`}
                      </span>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
