export const PREPARE_CHECKOUT = 'PREPARE_CHECKOUT';
export const SET_CHECKOUT = 'SET_CHECKOUT';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';

export const CHECKOUT_ORDER = 'CHECKOUT_ORDER';
export const SET_CHECKOUT_ORDER = 'SET_CHECKOUT_ORDER';

export const SET_LOADING = 'SET_LOADING';

export const prepareCheckout = checkoutData => ({
  type: PREPARE_CHECKOUT,
  checkoutData,
});

export const setCheckout = checkout => ({
  type: SET_CHECKOUT,
  checkout,
});

export const getPaymentStatus = (resourcePath, checkoutid) => ({
  type: GET_PAYMENT_STATUS,
  resourcePath,
  checkoutid,
});

export const setPaymentStatus = status => ({
  type: SET_PAYMENT_STATUS,
  status
});

export const checkoutOrder = order => ({
  type: CHECKOUT_ORDER,
  order,
});

export const setCheckoutOrder = order => ({
  type: SET_CHECKOUT_ORDER,
  order,
});

export const setLoading = loading => ({
  type: SET_LOADING,
  loading,
});
