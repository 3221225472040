import * as types from 'constants/actions';

export const getComments = () => ({
  type: types.GET_COMMENTS
});

export const addComment = comment => ({
  type: types.ADD_COMMENT,
  comment
});

export const setComments = comments => ({
  type: types.SET_COMMENTS,
  comments
});
