import * as types from 'constants/actions';

export const getEntrances = () => ({
  type: types.GET_ENTRANCES
});

export const setEntrances = entrances => ({
  type: types.SET_ENTRANCES,
  entrances
});

export const addEntrance = entrance => ({
  type: types.ADD_ENTRANCE,
  entrance
});

export const updateEntrance = (id, entrance, action = () => {}) => ({
  type: types.UPDATE_ENTRANCE,
  id,
  entrance,
  action
});
