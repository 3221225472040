import * as types from 'constants/actions';

export const getGuides = () => ({
  type: types.GET_GUIDES
});

export const setGuides = guides => ({
  type: types.SET_GUIDES,
  guides
});

export const getSingleGuide = id => ({
  type: types.GET_SINGLE_GUIDE,
  id
});

export const setSingleGuide = guide => ({
  type: types.SET_SINGLE_GUIDE,
  guide
});
