import * as types from 'constants/actions';

export const createUser = (email, password, user) => ({
  type: types.CREATE_USER,
  email,
  password,
  user
});

export const loginUser = (email, password) => ({
  type: types.LOGIN_USER,
  email,
  password
});

export const logout = () => ({
  type: types.LOGOUT
});

export const deleteProfile = () => ({
  type: types.DELETE_PROFILE
});

export const setLoginStatus = successfulLogin => ({
  type: types.SET_LOGIN_STATUS,
  successfulLogin
});

export const setIsLoggingStatus = isLoggingIn => ({
  type: types.SET_LOGGING_STATUS,
  isLoggingIn
});

export const setVerifyStatus = verifiedEmail => ({
  type: types.SET_VERIFY_STATUS,
  verifiedEmail
});

export const setVerifyStatusFalse = verifiedEmail => ({
  type: types.SET_VERIFY_STATUS_FALSE,
  verifiedEmail
});

export const sendResetPasswordEmail = email => ({
  type: types.SEND_RESET_PASSWORD_EMAIL,
  email
});

export const resetPassword = (code, user) => ({
  type: types.RESET_PASSWORD,
  code,
  user
});

export const verifyEmail = (code, user) => ({
  type: types.VERIFY_EMAIL,
  code,
  user
});

export const updatePassword = (email, oldpassword, newpassword) => ({
  type: types.UPDATE_PASSWORD,
  email,
  oldpassword,
  newpassword
});

export const updateEmail = (oldemail, newemail, password) => ({
  type: types.UPDATE_EMAIL,
  oldemail,
  newemail,
  password
});
