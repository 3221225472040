import React from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Message } from "primereact/message";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Spinner } from "primereact/spinner";
import { InputTextarea } from "primereact/inputtextarea";
import PhoneCode from "react-phone-code";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const textField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <InputText
      className={`${touched && error && "p-error"}`}
      id={name}
      {...input}
      {...custom}
    />
  </span>
);

export const textAreaField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <InputTextarea rows={5} autoResize id={name} {...input} {...custom} />
  </span>
);

export const passwordField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <Password
      className={`${touched && error && "p-error"}`}
      id={name}
      {...input}
      {...custom}
    />
    {touched && error && (
      <div className="mb-2">
        <Message
          severity="error"
          text="Password must be at least 6 characters"
        />
      </div>
    )}
  </span>
);

export const maskField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  disabled,
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <InputMask
      id={name}
      value={input.value}
      onChange={(e) => input.onChange(e.value)}
      {...custom}
    />
  </span>
);

export const phoneCodeField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <PhoneCode
      onSelect={(code) => input.onChange(code)} // required
      showFirst={["MT"]}
      defaultValue={["MT"]}
      id={name}
    />
  </span>
);

export const phoneField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <ReactPhoneInput
      defaultCountry="mt"
      value={input.value}
      onChange={(e) => input.onChange(e)}
    />
  </span>
);

export const calendarField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <Calendar
      id={name}
      value={input.value}
      onChange={(e) => input.onChange(e.value)}
      {...custom}
    />
  </span>
);

export const dropdownField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <Dropdown
      id={name}
      value={input.value}
      onChange={(e) => input.onChange(e.value)}
      {...custom}
    />
  </span>
);

export const spinnerField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    {label && <label htmlFor={name}>{label}</label>}
    <Spinner
      id={name}
      value={input.value || 0}
      onChange={(e) => input.onChange(e.value)}
      {...custom}
    />
  </span>
);

export const checkboxField = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    <Checkbox
      id={name}
      onChange={(e) => input.onChange(e.checked)}
      checked={input.value || false}
      {...custom}
    />
  </span>
);

export const rememberMe = ({
  label,
  input,
  name,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <span>
    <Checkbox
      inputId={name}
      value="Remember Me"
      onChange={(e) => input.onChange(e)}
      checked={input.value}
    />
    <span className="ml-2">{label}</span>
  </span>
);
