import { all, takeLatest, call, put} from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import Firestore from 'firebase-service/Firestore';
import { success } from 'helpers/reactToaster';
import * as actions from './actions';

const firestore = new Firestore();

function* getPickups() {
  const result = yield call(firestore.getRSF().firestore.getCollection, firestore.get().collection('pickups'));

  yield put(actions.setPickups(firestore.sanitizeSnapshot(result, fields.PICKUP)));

}

function* addPickup({pickup}) {
  try {
    yield call(firestore.getRSF().firestore.addDocument, 'pickups', pickup);
    success('Pick up successfully added');
    yield put(actions.getPickups());
  } catch (e) {
    console.log(e);
  }
}

function* updatePickup({id, pickup}) {
  try {
    yield call(firestore.getRSF().firestore.updateDocument, `pickups/${id}`, pickup);
    success('Pick up successfully updated');
    yield put(actions.getPickups());
  } catch (e) {
    console.log(e);
  }
}

function* deletePickup({id}) {
  try {
    yield call(firestore.getRSF().firestore.deleteDocument, `pickups/${id}`);
    success('Pick up successfully deleted');
    yield put(actions.getPickups());
  } catch (e) {
    console.log(e);
  }
}

function* pickupsSaga() {
  yield all([
    takeLatest(types.GET_PICKUPS, getPickups),
    takeLatest(types.ADD_PICKUP, addPickup),
    takeLatest(types.UPDATE_PICKUP, updatePickup),
    takeLatest(types.DELETE_PICKUP, deletePickup)
  ]);
}

export default pickupsSaga;
