import * as types from 'constants/actions';

export const getRestaurants = () => ({
  type: types.GET_RESTAURANTS
});

export const setRestaurants = restaurants => ({
  type: types.SET_RESTAURANTS,
  restaurants
});
