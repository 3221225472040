import * as types from 'constants/actions';

export const getTransports = () => ({
  type: types.GET_TRANSPORTS
});

export const setTransports = transports => ({
  type: types.SET_TRANSPORTS,
  transports
});

export const addTransport = transport => ({
  type: types.ADD_TRANSPORT,
  transport
});

export const updateTransport = (id, transport) => ({
  type: types.UPDATE_TRANSPORT,
  id,
  transport
});

export const deleteTransport = transportId => ({
  type: types.DELETE_TRANSPORT,
  transportId
});
