import React, { Component } from 'react';
import {Calendar} from 'primereact/calendar';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import InputRange from 'react-input-range';

export class Filter extends Component {
  state={
    displayFilter: false,
    value: { min: 0, max: 0 },
  }

  componentDidMount() {
    this.updateRangeState();
  }

  componentDidUpdate(prevProps) {
    const { filterPrice } = this.props;
    if (filterPrice !== prevProps.filterPrice) {
      this.updateRangeState();
    }
  }

  updateRangeState() {
    const { filterPrice } = this.props;
    this.setState(state => ({
      value: {
        ...state.value,
        min: filterPrice[0],
        max: filterPrice[1],
      },
    }));
  }

  handleSliderChange = (value) => {
    const { latestTours, filterToursByPrice } = this.props;
    filterToursByPrice([value.min, value.max], latestTours);
  }

  render() {
    const {
      latestTours,
      filterPlaces,
      filterPrice,
      filterName,
      filterDates,
      filterCount,
      filterToursByPlace,
      filterToursByName,
      filterToursByDate,
      resetFilters,
      getPopularPlaces} = this.props;

    const {displayFilter, value} = this.state;
    return (
      <div>
        <div
          className={`row p-0 hidden-filter ${
            (displayFilter && 'display-hidden-filter') || ''
          }`}
        >
          <div className="col">
            <div
              className={`filterbox mt-1 mb-3 shadow animated ${
                displayFilter ? 'slideInDown' : 'slideOutUp'
              }`}
            >
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <label className="text-secondary font-weight-normal">
                        Places
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex">
                      {getPopularPlaces().map((place, i) => (
                        <span key={i} className="mr-3">
                          <Checkbox
                            value={place.place}
                            className="p-checkbox-box-danger"
                            onChange={e => filterToursByPlace(e, latestTours)}
                            checked={filterPlaces.includes(place.place)}
                          />
                          <label
                            htmlFor="cb2"
                            className="p-checkbox-label font-weight-normal text-secondary"
                          >{`${place.place} (${place.count})`}
                          </label>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <label className="text-secondary font-weight-normal">
                        Price
                      </label>
                      <label className="pull-right text-secondary">
                        {filterPrice[1] !== 0
                          ? `€ ${filterPrice[0]} - € ${filterPrice[1]}`
                          : ''}
                      </label>
                      {/* <Slider className="mt-3" value={filterPrice} min={0} max={200} onChange={e => filterToursByPrice(e.value, latestTours)} range /> */}

                      <InputRange
                        allowSameValues
                        minValue={0}
                        maxValue={200}
                        value={value}
                        onChange={value => this.handleSliderChange(value)}
                        labelContainer=""
                        formatLabel={(value, number) => ''}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: '30px' }}>
                <div className="col d-flex justify-content-end border-top pt-3">
                  {/* <Button
                    label="Clear Filters"
                    className="p-button-raised p-button-danger"
                    onClick={() => resetFilters()}
                  /> */}
                  <Button
                    label="Close"
                    className="p-button-raised p-button-secondary ml-3"
                    onClick={() => this.setState({ displayFilter: false })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col text-center">
            <div className="p-inputgroup" id="searchHome">
              <span className="p-inputgroup-addon">
                <i className="pi pi-search" />
              </span>
              <InputText
                placeholder="Search by tour name or attraction"
                value={filterName}
                onChange={e => filterToursByName(e, latestTours)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="shadow mt-1 mb-3 filterbox">
              <div className="row">
                <div className="col" style={{ paddingRight: '30px' }}>
                  <label className="text-secondary font-weight-normal">
                    Date Range
                  </label>
                  <Calendar
                    value={filterDates}
                    readOnlyInput
                    onChange={e => filterToursByDate(e.value, latestTours)}
                    selectionMode="range"
                    minDate={new Date()}
                    dateFormat="dd/mm/yy"
                  />
                </div>
                <div
                  className="col border-left"
                  style={{ paddingLeft: '30px' }}
                >
                  {/* <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <label className="text-secondary font-weight-normal">
                        Filters
                      </label>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <Button
                        label="Clear Filters"
                        className="p-button-raised p-button-danger"
                        onClick={() => resetFilters()}
                      />
                    </div>
                  </div> */}
                  <div className="row" style={{ marginBottom: '10px'}}>
                    <div className="col d-flex">
                      <h4 className="display-5 mt-2 mr-auto">
                        Filters
                      </h4>
                      <Button
                        label="Clear Filters"
                        className="p-button-raised p-button-danger"
                        onClick={() => resetFilters()}
                        style={{ width: '120px '}}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex">
                      <h4 className="display-5 font-weight-bold mt-2 mr-auto">{`${
                        filterCount > 0
                          ? filterCount
                            + (filterCount > 1 ? ' Filters' : ' Filter')
                          : 'No Filter'
                      } Applied`}
                      </h4>
                      <Button
                        label="Add Filter"
                        className="p-button-raised p-button-danger"
                        onClick={() => this.setState({ displayFilter: true })}
                        style={{ width: '120px '}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Filter;
