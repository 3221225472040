import * as types from 'constants/actions';

const initState = {
  orders: null,
  userOrders: null
};

export default (state = initState, action) => {
  const { orders, type } = action;

  switch (type) {
    case types.SET_ORDERS:
      return { ...state, orders };
    case types.SET_USER_ORDERS:
      return { ...state, userOrders: orders };
    default:
      return { ...state };
  }
};
