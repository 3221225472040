import * as types from 'constants/actions';

export const getTours = () => ({
  type: types.GET_TOURS
});

export const getSingleTour = id => ({
  type: types.GET_SINGLE_TOUR,
  id
});

export const updateTourOrder = id => ({
  type: types.UPDATE_TOUR_ORDER,
  id
});

export const setTours = tours => ({
  type: types.SET_TOURS,
  tours
});

export const rateTour = (id, rate) => ({
  type: types.RATE_TOUR,
  id,
  rate
});

export const setSingleTour = tour => ({
  type: types.SET_SINGLE_TOUR,
  tour
});
