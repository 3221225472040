import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import { success } from 'helpers/reactToaster';
import Firestore from 'firebase-service/Firestore';
import * as actions from './actions';

const firestore = new Firestore();

function* getTransports() {
  const result = yield call(firestore.getRSF().firestore.getCollection, firestore.get().collection('transport'));

  yield put(actions.setTransports(firestore.sanitizeSnapshot(result, fields.TRANSPORT)));
}

function* addTransport({transport}) {
  try {
    yield call(firestore.getRSF().firestore.addDocument, 'transport', transport);

    success('Transport successfully added');
    yield put(actions.getTransports());
  } catch (e) {
    console.log(e);
  }
}

function* updateTransport({id, transport}) {
  try {
    yield call(firestore.getRSF().firestore.updateDocument, `transport/${id}`, transport);

    yield put(actions.getTransports());
    success('Transport successfully updated');
  } catch (e) {
    console.log(e);
  }
}

function* deleteTransport({transportId}) {
  try {
    yield call(firestore.getRSF().firestore.deleteDocument, `transport/${transportId}`);
    yield put(actions.getTransports());

    success('Transport successfully deleted');
  } catch (e) {
    console.log(e);
  }
}

function* transportsSaga() {
  yield all([
    takeLatest(types.GET_TRANSPORTS, getTransports),
    takeLatest(types.ADD_TRANSPORT, addTransport),
    takeLatest(types.UPDATE_TRANSPORT, updateTransport),
    takeLatest(types.DELETE_TRANSPORT, deleteTransport)
  ]);
}

export default transportsSaga;
