import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import {Card} from 'primereact/card';
import { textField, passwordField } from 'helpers/reduxForms';
import {windowHeight} from 'constants/height';

export class ResetPasswordForm extends Component {
  onSubmit = (values) => {
    const { onSubmit, destroy } = this.props;

    destroy();
    onSubmit(values);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div className="container" style={{minHeight: windowHeight + 30}}>
        <div className="row">
          <div className="col-lg-4 offset-lg-4" style={{marginTop: 150}}>
            <Card>
              <div className="row">
                <div className="col border-bottom mb-3 text-center">
                  <h2>Reset Password</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="row">
                  <div className="col">
                    <Field
                      name="email"
                      component={textField}
                      label="Email"
                      disabled
                  />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <Field
                      name="password"
                      component={passwordField}
                      label="New Password"
                      autoComplete="new-password"
                  />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <button type="submit" className="btn btn-secondary btn-lg btn-block">Reset Password</button>
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'REQUIRED_PASSWORD';
  } else if (values.password.length < 6) {
    errors.password = 'INVALID_PASSWORD_LENGTH';
  }

  return errors;
};

export default reduxForm({
  form: 'resetPasswordForm',
  validate
})(ResetPasswordForm);
