import React, { Component } from 'react';
import ProfileForm from 'components/forms/ProfileForm';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { confirm } from 'helpers/reactToaster';
import { getCurrentUser, getUsers, updateUser } from '../services/users/actions';
import { updateEmail, updatePassword, deleteProfile } from '../services/auth/actions';
import {checkIsLoggedIn} from '../helpers/authentication';

export class Profile extends Component {

  componentDidMount() {
    const { getCurrentUser, history } = this.props;

    if (!checkIsLoggedIn()) {
      history.push('/');
    }
    getCurrentUser();
  }

  updateCurrentUser = (values) => {
    const {user, updateUser} = this.props;
    const currentUser = user;
    let toUpdateEmail = false;
    let toUpdatePassword = false;
    let changedAdminInfo = false;

    if (values.email !== currentUser.email) {
      values.oldemail = currentUser.email;
      toUpdateEmail = true;
    }
    if (!values.oldpassword && values.newpassword) {
      delete values.newpassword;
    }
    if (values.oldpassword && (values.email === currentUser.email) && !values.newpassword) {
      delete values.oldpassword;
    }
    if (values.oldpassword && values.newpassword) {
      values.oldemail = currentUser.email;
      toUpdatePassword = true;
    }

    if (!currentUser.agent) {
      delete values.commission;
    }

    if (values !== currentUser) {
      changedAdminInfo = true;
    }
    const userid = values.uuid;
    delete values.uuid;
    values.deactivated = false;
    updateUser(userid, values, toUpdateEmail, toUpdatePassword, changedAdminInfo);
  }

  delProfile = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { deleteProfile } = this.props;
    confirm('Are you sure you want to delete your account?', () => deleteProfile());
  }

  render() {
    const {user} = this.props;
    const currentUser = user;
    return (
      <ProfileForm
        initialValues={currentUser}
        onSubmit={this.updateCurrentUser}
        deleteProfile={e => this.delProfile(e)}
        />
    );
  }
}

export default withRouter(connect(({users}) => ({
  user: users.user
}),
{
  deleteProfile,
  getUsers,
  updateUser,
  updateEmail,
  updatePassword,
  getCurrentUser
})(Profile));
