import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ResetPasswordForm from 'components/forms/ResetPasswordForm';
import queryString from 'query-string';
import { success, error } from 'helpers/reactToaster';
import {windowHeight} from 'constants/height';
import firebase from 'firebase/app';
import { resetPassword, verifyEmail } from '../services/auth/actions';
import 'firebase/auth';

export class ResetPassword extends Component {
  state = {
    successfulLogin: null
  };

  static getDerivedStateFromProps({successfulLogin}, prevState) {
    return {
      ...prevState,
      successfulLogin
    };
  }

  componentDidMount() {
    const { location, history } = this.props;
    const {mode} = queryString.parse(location.search, { ignoreQueryPrefix: true });
    const {oobCode} = queryString.parse(location.search, { ignoreQueryPrefix: true });
    if (mode === 'verifyEmail') {
      firebase.auth().applyActionCode(oobCode).then((resp) => {
        success('Email has been successfully verified!');
        history.push('/login');
      }).catch((err) => {
        if (err.code === 'auth/invalid-action-code') {
          history.push('/message/invalidCode');
        } else {
          error(err.message);
          history.push('/login');
        }
        console.log(err);
      });
    }
  }

  componentDidUpdate() {
    const { successfulLogin } = this.state;
    const { history } = this.props;

    if (successfulLogin !== null) {
      if (successfulLogin) {
        this.setState({successfulLogin: null});
        history.push('/');
      }
    }
  }

  resetUserPassword = (values) => {
    const { location, resetPassword } = this.props;
    const {email} = queryString.parse(location.search, { ignoreQueryPrefix: true });
    const {oobCode} = queryString.parse(location.search, { ignoreQueryPrefix: true });
    const user = {email, password: values.password};
    console.log(user);
    resetPassword(oobCode, user);
  }

  render() {
    const { location } = this.props;
    const queryString = require('query-string');
    const {email, mode} = queryString.parse(location.search, { ignoreQueryPrefix: true });
    return (
      <div style={{minHeight: windowHeight + 30}}>
        {
          mode === 'resetPassword' && <ResetPasswordForm initialValues={{email}} onSubmit={this.resetUserPassword} />
        }
      </div>
    );
  }
}

export default withRouter(connect(({authentication, users}) => ({
  successfulLogin: authentication.successfulLogin
}),
{
  resetPassword,
  verifyEmail
})(ResetPassword));
