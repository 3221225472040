import React, {Fragment} from 'react';
import {Rating} from 'primereact/rating';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import ScrollAnimation from 'react-animate-on-scroll';
import { longSecondsToDate } from '../../helpers/dateTime';

const Reviews = (props) => {
  const {
    tourComments,
    rate, comment, users,
    hideRate, checkDoneTour,
    checkDoneRating, setRate,
    setComment, addUserComment,
    commentDate} = props;
  return (
    <div className="row">
      {
        checkDoneTour() && (
        <div className="col-12">
          <div className="review-container shadow animated pulse">
            <div className="row pt-3 pl-3 pr-3 mb-2">
              <div className="col d-flex">
                <label className="mr-3">Leave a Review: </label>
                {
                  !hideRate && !checkDoneRating() && (<Rating cancel={false} value={rate || 0} onChange={e => setRate(e.value)} />)
                }
              </div>
            </div>
            <div className="row pl-3 pr-3 pb-3">
              <div className="col d-flex">
                <InputTextarea className="comment-area" autoResize value={comment || ''} onChange={e => setComment(e.target.value)} />
                <Button className="red-button ml-3" style={{height: '40px', width: '140px'}} label="SUBMIT" onClick={() => addUserComment()} />
              </div>
            </div>
          </div>
        </div>
        )
              }
      { tourComments.length === 0 ? (
        <div className="col-md-12">
          <p className="lead font-weight-normal">While our developers work hard to build a comprehensive review section to showcase the experiences of our valued guests,
          we invite you to visit our <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x6fbd120d8d82ff61:0x8027405b6c303a94!12e1?source=g.page.m.dd._&laa=lu-desktop-reviews-dialog-review-solicitation" target="_blank">Google Reviews</a> page for a collection of honest feedback about our tours and activities from clients who have already been on our tours and activities.
          </p>
        </div>
      ) : null}
      { tourComments && tourComments.map((comment, i) => {
        const user = (users && users.find(user => comment.user === user.userid)) || {firstname: '', lastname: ''};

        return (
          <Fragment key={i}>
            <div className="col-md-12 mb-0">
              <ScrollAnimation animateIn="fadeIn" animateOnce>
                <div className="media">
                  <img
                    alt="profile"
                    src="https://www.w3schools.com/howto/img_avatar.png"
                    className="mr-3 rounded-circle"
                    style={{width: '46px', height: '45.906px'}} />
                  <div className="media-body">
                    <h4 className="font-weight-bold mt-0">
                      { !user.firstname || !user.lastname ? user.email : `${user.firstname} ${user.lastname}`}
                    </h4>
                    <p className="text-secondary font-weight-small  mb-0">{ commentDate(longSecondsToDate(comment.timeadded.seconds)) }</p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-md-12 mt-3">
              <ScrollAnimation animateIn="fadeIn" animateOnce>
                <p className="text-secondary font-weight-normal text-justify">
                  { comment.comment }
                </p>
                { i !== tourComments.length - 1 ? (<hr className="mt-0" />) : null}
              </ScrollAnimation>
            </div>
          </Fragment>
        );
      })}

    </div>
  );
};

export default Reviews;
