import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  PREPARE_CHECKOUT,
  GET_PAYMENT_STATUS,
  setCheckout,
  setPaymentStatus,
  CHECKOUT_ORDER,
  setCheckoutOrder,
  setLoading,
} from './actions';
import config from '../../constants/payments';

import Firestore from '../../firebase-service/Firestore';
import { addOrder } from '../orders/actions';
import { MALTATOURS_CONFIG } from '../../firebase-service/config';

const firestore = new Firestore();

function* prepareCheckout({ checkoutData }) {
  try {
    const path = '/v1/checkouts';
    const data = {
      entityId: `${config.ENTITY_ID}`,
      currency: 'EUR',
      paymentType: 'DB',
      ...checkoutData,
      testMode: config.testMode,
    };

    const options = JSON.stringify({
      port: 443,
      uri: `${config.BASE_URL}${path}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Length': data.length,
        Authorization:
          `Bearer ${config.AUTHORIZATION}`,
      },
      form: data,
      json: true,
    });

    const prepareCheckout = yield call(
      firestore.getRSF().functions.call,
      `${MALTATOURS_CONFIG.cf}/createCheckout`,
      {},
      {
        method: 'POST',
        body: options,
      }
    );

    yield put(setCheckout(prepareCheckout));
  } catch (e) {
    console.log('checkout error', e);
  }
}

function* checkPaymentStatus({ resourcePath, checkoutid }) {
  try {
    const { checkoutOrder } = yield select(state => state.payments);
    const functionCall = firestore.getRSF().functions.call;

    const options = JSON.stringify({
      uri: `${config.BASE_URL}${resourcePath}`,
      json: true,
    });

    const [paymentStatus] = yield all([
      call(
        functionCall,
        `${MALTATOURS_CONFIG.cf}/paymentStatus`,
        {},
        {
          method: 'POST',
          body: options,
        }
      )
    ]);

    const successTransactionRegEx = /^(000\.000\.|000\.100\.1|000\.[36])/;
    if (paymentStatus && !_.has(paymentStatus, 'statusCode') && successTransactionRegEx.test(paymentStatus.result && paymentStatus.result.code) && !checkoutOrder.payment) {
      const body = {
        ...checkoutOrder,
        payment: paymentStatus,
      };
      yield put(addOrder(body));
    }

    const payment = {
      ...paymentStatus,
      tourname: checkoutOrder.tourName,
      orderRef: checkoutOrder.ref,
    };
    yield put(setPaymentStatus(payment));
  } catch (e) {
    yield put(setLoading(false));
    console.log('err', e);
  }
}

// eslint-disable-next-line require-yield
function* checkoutOrder({ order }) {
  try {
    yield put(setCheckoutOrder(order));
  } catch (e) {
    console.log('checkout error', e);
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(PREPARE_CHECKOUT, prepareCheckout),
    takeLatest(GET_PAYMENT_STATUS, checkPaymentStatus),
    takeLatest(CHECKOUT_ORDER, checkoutOrder)
  ]);
}

export default paymentSaga;
