// import moment from 'moment';
import moment from 'moment-timezone';

const UTF_OFFSET = '+1600';

export const dateToTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
};

export const timeToDate = (time) => {
  const timeArray = time.split(':');

  const date = new Date();

  date.setHours(timeArray[0]);
  date.setMinutes(timeArray[1]);

  return date;
};

export const toDate = (date, format = 'YYYY-MM-DD') => {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  let formatted = format.replace('YYYY', year);
  formatted = formatted.replace('MM', month);
  formatted = formatted.replace('DD', day);
  formatted = formatted.replace('HH', hours);
  formatted = formatted.replace('mm', minutes);
  formatted = formatted.replace('SS', seconds);

  return formatted;
};

export const longSecondsToDate = (seconds) => {
  const date = new Date(1970, 0, 1);

  date.setSeconds(seconds);

  return date;
};

export const getFirestoreDate = () => {
  const date = new Date();

  return {
    seconds: date.getSeconds(),
    nanoseconds: 0
  };
};

export const formatSecondsToDate = (seconds, format = 'YYYY-MM-DD') => {
  const date = new Date(1970, 0, 1);
  date.setSeconds(seconds);

  return moment(date.getTime()).utcOffset(UTF_OFFSET).format(format);
};

export const commentDate = (date) => {
  return date.getDate() + ' ' + "January,February,March,April,May,June,July,August,September,October,November,December".split(',')[date.getMonth()].toLowerCase() + ' ' + date.getFullYear();
};

export const formatTime = (time) => {

  if (!time) {
    return '';
  }

  const tempTime = time.split(':');
  let halfDay = 'AM';
  let hours = tempTime[0];
  if (Number(hours) > 12) {
    hours -= 12;
    halfDay = 'PM';
  }
  tempTime[0] = hours;
  return (`${tempTime.join(':')} ${halfDay}`);
};

export const getDays = (startDate, endDate) => {
  const dates = [];
  let currentDate = startDate;
  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};
