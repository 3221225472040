import * as types from 'constants/actions';

const initState = {
  successfulLogin: null,
  isLoggingIn: null,
  verifiedEmail: null
};

export default (state = initState, action) => {
  const { successfulLogin, isLoggingIn, verifiedEmail, type } = action;

  switch (type) {
    case types.SET_LOGIN_STATUS:
      return { ...state, successfulLogin };
    case types.SET_LOGGING_STATUS:
      return { ...state, isLoggingIn };
    case types.SET_VERIFY_STATUS:
      return { ...state, verifiedEmail };
    default:
      return { ...state };
  }
};
