import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import Firestore from 'firebase-service/Firestore';
import * as actions from './actions';

const firestore = new Firestore();

function* getGuides() {
  const result = yield call(firestore.getRSF().firestore.getCollection, firestore.getCollection('guide'));
  const guides = firestore.sanitizeSnapshot(result, fields.GUIDE);

  yield put(actions.setGuides(guides));
}

function* getSingleGuide(id) {
  console.log(id);
  const result = yield call(firestore.getRSF().firestore.getDocument, `guide/${id.id}`);
  const singleGuide = firestore.sanitizeSingleSnapshot(result, fields.GUIDE);

  yield put(actions.setSingleGuide(singleGuide));
}

function* guidesSaga() {
  yield all([
    takeLatest(types.GET_GUIDES, getGuides),
    takeLatest(types.GET_SINGLE_GUIDE, getSingleGuide)
  ]);
}

export default guidesSaga;
