import authenticationSaga from './auth/saga';
import usersSaga from './users/saga';

import toursSaga from './tours/saga';
import gallerySaga from './gallery/saga';
import guidesSaga from './guides/saga';
import commentsSaga from './comments/saga';
import pickupsSaga from './pickups/saga';
import entrancesSaga from './entrances/saga';
import transportsSaga from './transport/saga';
import restaurantsSaga from './restaurant/saga';

import ordersSaga from './orders/saga';
import paymentSaga from './payment/saga';

export default [
  toursSaga,
  gallerySaga,
  guidesSaga,
  authenticationSaga,
  usersSaga,
  ordersSaga,
  commentsSaga,
  pickupsSaga,
  entrancesSaga,
  transportsSaga,
  restaurantsSaga,
  paymentSaga,
];
