import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import { success, error } from 'helpers/reactToaster';
import 'firebase/firestore';
import Firestore from 'firebase-service/Firestore';
import * as actions from './actions';

const firestore = new Firestore();

function* addComment(value) {
  try {
    yield call(firestore.getRSF().firestore.addDocument, 'comment', value.comment);
    success('Comment has been added. Comments are subjected to review');
    yield put(actions.getComments());
  } catch (e) {
    console.log(e);
    error(e.message);
  }

}

function* getComments() {
  try {
    const result = yield call(firestore.getRSF().firestore.getCollection, firestore.get().collection('comment'));

    yield put(actions.setComments(firestore.sanitizeSnapshot(result, fields.COMMENT)));
  } catch (e) {
    console.log(e);
  }

}

function* commentsSaga() {
  yield all([
    takeLatest(types.ADD_COMMENT, addComment),
    takeLatest(types.GET_COMMENTS, getComments)
  ]);
}

export default commentsSaga;
