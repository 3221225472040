import { all, takeLatest, call, put } from 'redux-saga/effects';
import * as types from 'constants/actions';
import * as fields from 'constants/fields';
import { success } from 'helpers/reactToaster';
import Firestore from 'firebase-service/Firestore';
import * as actions from './actions';

const firestore = new Firestore();

function* getEntrances() {
  try {
    const result = yield call(firestore.getRSF().firestore.getCollection, firestore.get().collection('entrance'));

    yield put(actions.setEntrances(firestore.sanitizeSnapshot(result, fields.ENTRANCES)));
  } catch (e) {
    console.log(e);
  }
}

function* addEntrance({entrance}) {
  try {
    yield call(firestore.getRSF().firestore.addDocument, 'entrance', entrance);

    success('Entrance successfully added');
  } catch (e) {
    console.log(e);
  }
}

function* updateEntrance({id, entrance}) {
  try {
    yield call(firestore.getRSF().firestore.updateDocument, `entrance/${id}`, entrance);

    success('Entrance successfully updated');
    yield put(actions.getEntrances());
  } catch (e) {
    console.log(e);
  }
}

function* entrancesSaga() {
  yield all([
    takeLatest(types.ADD_ENTRANCE, addEntrance),
    takeLatest(types.GET_ENTRANCES, getEntrances),
    takeLatest(types.UPDATE_ENTRANCE, updateEntrance)
  ]);
}

export default entrancesSaga;
